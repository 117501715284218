$font-family-sans-serif: 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$body-bg: #333;
$body-color: #666;

$headings-color: #000;
$headings-font-weight: bold;
$headings-font-family: 'Roboto', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

$primary: #f8a75d;

$link-color: $primary;
$link-hover-color: $primary;

$navbar-padding-y: 0;
$navbar-padding-x: 0;

$navbar-nav-link-padding-x: 1rem;
$navbar-nav-link-padding-y: 12px;

$nav-tabs-link-active-bg: #f68c2c;

$navbar-light-color:                #fff;
$navbar-light-hover-color:          #fff;
$navbar-light-active-color:         #fff;
$navbar-light-disabled-color:       rgba(#fff, .3);

$navbar-link-bg: $primary;
$navbar-link-hover-bg: #f68c2c;
$navbar-link-active-bg: #f68c2c;

$hamburger-color: #fff;

$story-headings-color: #000;

@import "../../fonts/roboto";
@import "../../fonts/open-sans";
@import "../../magazine";

.brand-logo {
  picture {
    max-width: 150px;

    @include media-breakpoint-up(md) {
      max-width: 250px;
    }
  }
  img {
    height: auto;
    max-height: 40px;

    @include media-breakpoint-up(md) {
      max-height: 80px;
    }
  }
}

.navbar {
  background-color: $primary;

  .nav-link {
    color: $white;
    font-family: $font-family-sans-serif;
    @include font-size(1.25rem);

    @include hover-focus {
      background-color: $nav-tabs-link-active-bg;
    }
  }
}

@include media-breakpoint-down(sm) {
  .navbar-top {
    background-color: $primary;
  }

  .navbar-nav .nav-link {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

.navbar.navbar-expand  {
  .nav-item {
    @include nav-item-separator(#fff, $navbar-link-font-size);
  }
}

.navbar-collapse {
  &.collapsing,
  &.collapse.show {
    border-top: 1px solid #ddd;
  }
}

.sidebar .card.filled {
  background-color: #EEE8E0;

  .card-header {
    @include font-size(1rem);
  }
}

.section-caption {
  color: $primary;
  font-weight: $font-weight-normal;
  @include font-size(1rem);
}

.section-head .more {
  border-color: $primary;
  @include plain-hover-focus {
    color: $primary;
  }
}

.article-head p {
  color: #555;
}

article .pagination {
  font-weight: $font-weight-bold;
}
